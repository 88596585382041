import { Perimeter } from "vue-kindergarten"

export default class BasePerimeter extends Perimeter {
  fullPath = ""
  actions = ""

  isSuperAdmin() {
    return this.child && this.child.is_super_admin === true
  }

  isWebsiteBuilder() {
    return this.child && this.child.is_website_builder === true
  }

  setFullPath(fullPath) {
    this.fullPath = fullPath
    this.actions = ""
    if (this.child && this.child.permissions) {
      for (let perm of this.child.permissions) {
        /**
         * ! Everyone has permission to Lead Manager
         */
        if (fullPath.includes('leads')) {
          this.actions = 'view,edit,delete'
          break
        }
        /**
         * ! Everyone has permission to Email Reports
         */
        else if (fullPath.includes('email-reports')) {
          this.actions = 'view,edit,delete'
          break
        }
        /**
         * ! Everyone has permission to The Home Page
         */
        else if (fullPath.includes('home')) {
          this.actions = 'view,edit,delete'
          break
        }
        else if (fullPath.includes('teammates')) {
          this.actions = 'view,edit,delete'
          break
        }
        else if (fullPath.includes(perm.permission.key)) {
          // console.log('Found Permission: ', perm.actions)
          this.actions = perm.actions
          break
        }
      }
    }
  }

  canViewDashRedirect() {
    return true
  }

  canView() {
    return this.actions.includes("view")
  }

  canEdit() {
    return this.actions.includes("edit")
  }

  canDelete() {
    return this.actions.includes("delete")
  }

  isSuperSuperAdmin() {
    // console.log(this.child.email)
    return this.child && [
      "matt@storagepug.com",
      "richard@storagepug.com",
      "jonathan@storagepug.com",
      "tommy@storagepug.com",
      "tomasz@storagepug.com",
      "ana@storagepug.com",
      "marcelin@storagepug.com"
    ].includes(this.child.email)
  }
}
